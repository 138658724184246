import { intVal } from "@jamesgmarks/utilities";
import { Subscriptions } from "@llws/typeorm-entities";
import {
  Grid, InputAdornment, MenuItem, Select, TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getMany, getOne } from "../../../redux/features/dynamic/actions";
import { useAppSelector } from "../../../redux/hooks";
import { BaseSubscriptionDropdown } from "../../parts/BaseSubscriptionDropdown";
import { ClientDropdown } from "../../parts/ClientDropdown";
import { OneTimeFee } from "./EditorComponents/OneTimeFee";
import { RecurringSubscription } from "./EditorComponents/RecurringSubscription";
import { HHDatePicker } from "../../parts/HHDatePicker";

/**
 * "Starting" Level components
 *  - free trial
 *  - one time fee
 *  - recurring subscription
 */

export const SubscriptionEditor = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const storeSubscription = useAppSelector((store) => store.dynamic.data.subscriptions?.single);
  const billingFrequencies = useAppSelector((store) => store.dynamic.data.billing_frequencies?.list);

  const [ subscription, setSubscription ] = useState<Subscriptions | null>(
    storeSubscription as unknown as Subscriptions ?? null,
  );

  const setSubscriptionProp = useCallback(<T extends keyof Subscriptions>(prop: T, newVal: Subscriptions[T]) => {
    if (!subscription) return;
    setSubscription({ ...subscription, [prop]: newVal });
  }, [ subscription, setSubscription ]);

  useEffect(() => setSubscription(storeSubscription as unknown as Subscriptions), [ storeSubscription ]);

  useEffect(() => {
    getOne('subscriptions', subscriptionId, [
      { path: 'client', alias: 'c' },
      { path: 'baseSubscription', alias: 'bs' },
      { path: 'bs.service', alias: 'svc' },
      { path: 'bs.partner', alias: 'p' },
      { path: 'subscriptionsHasBuildings', alias: 'shb' },
      { path: 'parentSubscription', alias: 'ps' },
      { path: 'workInProgressQueues', alias: 'wipqs' },
      { path: 'wipqs.workInProgress', alias: 'wip' },
    ]);
    getMany('billing_frequencies');
  }, [ subscriptionId ]);

  const accountType = useMemo(() => (subscription?.handleBilling ? 'client' : 'partner'), [ subscription ]);

  const subscriptionFeeType = useMemo(
    () => subscription?.billingFrequencyId === 1 ? 'ONE_TIME' : 'RECURRING',
    [ subscription ],
  );

  const wipQueue = useMemo(
    () => subscription?.workInProgressQueues?.[0],
    [ subscription ],
  );

  return <>
    <header className="Section-header">Subscription Editor: {subscriptionId} {subscription?.client?.name}</header>

    <Grid container justifyContent="center" alignContent="center">
      <Grid container item xs={10}>
        {/* START: On Create only */}
        <Grid item xs={12}>
          Fee Type: { subscriptionFeeType === 'RECURRING' ? 'Recurring': 'One-time' }
        </Grid>

        <Grid item xs={12}>
          Account Type:
          <Select
            label="Account Type"
            value={accountType}
            onChange={(e) => setSubscriptionProp('handleBilling', e.target.value === "client")}
            disabled={!subscription?.id}
            size="small"
          >
            <MenuItem value="">-- SELECT --</MenuItem>
            <MenuItem value="client">Client</MenuItem>
            <MenuItem value="partner">Partner</MenuItem>
          </Select>
        </Grid>

        {
          subscription
          && <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <div>Account:</div>
              <div>
                <ClientDropdown
                  clientId={subscription.clientId}
                  onClientChanged={(client) => {
                    if ((client?.id ?? null) !== subscription.clientId) {
                      setSubscriptionProp('clientId', client?.id ?? null);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <BaseSubscriptionDropdown
                label='Product'
                baseSubscriptionId={subscription.baseSubscriptionId}
                onBaseSubscriptionChanged={(bs) => {
                  // TODO: Handle null selection.
                  if (bs?.id) { setSubscriptionProp('baseSubscriptionId', bs.id); }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'PO Number'}
                type="text"
                size="small"
                value={subscription.poNumber ?? ''}
                onChange={(e) => setSubscriptionProp('poNumber', e.target.value || null)}
              />
            </Grid>
            {/* END: On Create only */}

            <Grid item xs={6}>
              <TextField
                label={'Quote ID'}
                type="text"
                size="small"
                value={subscription.quoteId ?? ''}
                onChange={(e) => setSubscriptionProp('quoteId', e.target.value || null)}
              />
            </Grid>
            <Grid item xs={6}>
              <HHDatePicker
                label="Rate Expiry Date"
                value={subscription.rateExpiryDate}
                onChange={(d: Date | null) => { if (d) { setSubscriptionProp('rateExpiryDate', d); } }}
              />

            </Grid>
            <Grid item xs={6}>

            Quote: <input type="file" />

            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Min Properties for Rate"
                type="number" // TODO: restrict to numbers
                size="small"
                value={subscription.minPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('minPropertiesForRate', e.target.value ? intVal(e.target.value) : null)
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max Properties for Rate"
                type="number" // TODO: restrict to numbers
                size="small"
                value={subscription.maxPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('maxPropertiesForRate', e.target.value ? intVal(e.target.value) : null)
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Invoice Description"
                type="text"
                size="small"
                fullWidth
                value={subscription.invoiceDescription}
                onChange={(e) => setSubscriptionProp('invoiceDescription', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Base Price"
                type="text"
                size="small"
                value={`${subscription.basePrice}`}
                onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />

            </Grid>

            {
              subscription
              && subscription?.billingFrequencyId === 1
              && <OneTimeFee
                subscription={subscription}
                setSubscriptionProp={setSubscriptionProp}
              />
            }

            {
              subscription
              && !([1, null, undefined].includes(subscription?.billingFrequencyId)) // Non-empty and not 1 (one-time)
              && <RecurringSubscription
                subscription={subscription}
                setSubscription={setSubscription}
                billingFrequencies={billingFrequencies ?? []}
              />
            }
          </Grid>
        }
      </Grid>
    </Grid>
  </>;
};
