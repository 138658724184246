import { TEditorComponentProps } from "./TEditorComponentProps";

export const VariableBasePrice = ({
  subscription, setSubscriptionProp,
}: TEditorComponentProps) => {

  /**
   * TODO:
   * <style>
      #variable-base-price-table {
        margin-bottom:0px;
      }
    </style>
   */
  return (<>
    <div className="input-section">
      <table id="variable-base-price-table" className="table">
        <thead>
          <tr>
            <th>Range</th>
            <th>Base Price</th>
            <th
              className="cpu-column"
              // eslint-disable-next-line max-len
              title="If this column is used, the base price for an item will be the base price for the given row, PLUS [ the number of units ABOVE the max_value from the previous row, multiplied by cost_per_unit]"
            >
                      Cost per Unit &nbsp;&nbsp; [?]
            </th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      {/* <?php if(!$read_only) { ?> */}
      <button type="button" id="override-vbp-button" className="small orange button">edit</button>
      {/* <?php } ?> */}
    </div>
  </>);
};
