import { Equal, Null } from "@llws/dynamic-router-utils";
import { useEffect, useMemo } from "react";
import { getMany } from "../../../../redux/features/dynamic/actions";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const PromotedListingContract = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  /*
    <?php
        $active_subs = array_values(Subscriptions::get_active_sub_summary_for_promoted_listing_contract($subscription_id));
    ?>
  */

  const isDefaultSubscription = useMemo(() => true, []);

  useEffect(() => {
    getMany('subscriptions', {
      alias: 's',
      where: [
        // Either this or this, but depends on how promoted listings are added.
        ...(isDefaultSubscription ? [ Null('s.parentSubscriptionId') ] : []),
        Equal('s.parentSubscriptionId', subscription.id),
      ],
    });
  }, [ subscription ]);

  return (<>

    <table className="table">
      <tbody>
        <tr>
          <td>
            <label>Active Subs:</label>
          </td>
          <td>
            <button className="small green button" id="show-active-sub-summary" >
              {/* <?= count($active_subs) ?> */}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <table id="active-sub-summary" className="table" style={{display: 'none'}}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Description</th>
          <th>Start Date</th>
          <th>Expiry Date</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </>);
};
