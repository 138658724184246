import { floatVal } from "@jamesgmarks/utilities";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { FlatFee } from "./FlatFee";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const OneTimeFee = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          type="text"
          size="small"
          label="Discount"
          id="discount"
          value={floatVal(subscription['discountRate']) * 100}
          onChange={(e) => setSubscriptionProp('discountRate', `${floatVal(e.target.value) / 100}`)}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Date"
          type="text"
          size="small"
          id="start-date"
          className="datepicker"
          name="subscription[start_date]"
          value={subscription.startDate ?? ''}
          onChange={(e) => setSubscriptionProp('startDate', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FlatFee subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
      </Grid>

    </Grid>
  );
};