/* eslint-disable @typescript-eslint/no-explicit-any */
import { first, floatVal, intVal } from "@jamesgmarks/utilities";
import { BillingFrequencies, Subscriptions } from "@llws/typeorm-entities";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BILLING_FREQUENCY_ONE_TIME,
  BILLING_FREQUENCY_DAILY,
  BILLING_FREQUENCY_WEEKLY,
  BILLING_FREQUENCY_MONTHLY,
  BILLING_FREQUENCY_QUARTERLY,
  BILLING_FREQUENCY_SEMI_ANNUALLY,
  BILLING_FREQUENCY_YEARLY,
} from '@llws/hydra-shared';

import { dateFromYmd, getDiscountRateFromSubscription, getMonthName } from "../../../../app-utils";
import { ActiveBuilding } from "./ActiveBuilding";
import { CallTracking } from "./CallTracking";
import { CostPerLead } from "./CostPerLead";
import { FlatFee } from "./FlatFee";
import { LiftSubscription } from "./LiftSubscription";
import { PromotedListing } from "./PromotedListing";
import { PromotedListingContract } from "./PromotedListingContract";
import { firstMatch } from "./util";
import { VariableBasePrice } from "./VariableBasePrice";
import { HHDatePicker } from "../../../parts/HHDatePicker";
import {
  FormControlLabel, Grid, InputAdornment, MenuItem, Select, Switch, TextField,
} from "@mui/material";
// const $: ((selector: any) => any) = () => {};

// const isLwsAdmin = $("#lws-admin").val() === "1";
/**
 * Prevent Non Lws Admin users from editing certain fields
 * The idea is that these subscriptions should be expired, then recreated with new data
 * Note: this was requested: https://www.wrike.com/open.htm?id=271336002 But I think they'll change their minds after being burned by it a few times.
 * todo: automatically expire and recreate subscriptions after significant modifications. ( add parent_subscription_id to subs table to keep track of the chain )
 * @param elementIds string[]
 */
// const preventModifications = (loadedSubscription: Subscriptions | null, elementIds: string[]) => {
//   // Override this if no invoices have ever been issued for this sub
//   if ($("#invoice-history-table tbody tr").length === 0) return;

//   // Override if it's an SEM subscription
//   if(loadedSubscription?.baseSubscription.service['invoiceItem'] === 'SEM') return;

//   if (loadedSubscription?.['id'] && !isLwsAdmin) {
//     elementIds.forEach(function (elementId) {
//       $('#' + elementId).prop("disabled", true);
//     });
//   }
// };

const BillingType = Object.freeze({
  FLAT_FEE: 1,
  ACTIVE_BUILDING: 2,
  COST_PER_LEAD: 3,
  PROPERTY_VOLUME: 4,
  CALL_VOLUME: 5,
  SMS_VOLUME: 6,
  CALL_RECORDING: 7,
  LINE_BUNDLE: 8,
  LINE_RECORDING_BUNDLE: 9,
  LIFT_BUILDING: 10,
  PROMOTED_LISTING_CONTRACT: 11,
  PROMOTED_LISTING: 12,
});

const callTrackingConfig = {
  componentName: 'call_tracking',
  component: CallTracking,
  frequencies: [],
};

const getTimeBreakdown = (dayCount: number) => ({
  years: Math.floor(dayCount / 365),
  months: Math.floor((dayCount % 365) / 30),
  days: dayCount % 365 % 30,
});

const calculateSubscriptionSpan = (
  { start, end, discountRate }
  : {
    start: number | null,
    end: number | null,
    discountRate: number,
  },
) => {
  if (!start || !end) {
    return {
      label: <>&infin;</>,
      color: 'rgb(255,255,255)',
    };
  }

  const timeDiff = end - start;
  const daysDiff = timeDiff / (24 * 60 * 60 * 1000);

  const isLongTrial = (discountRate === 1 && daysDiff > 14);
  const spansMoreThanAYear = daysDiff > 365;

  const color = firstMatch([
    [ isLongTrial, 'rgb(255,0,0)' ],
    [ spansMoreThanAYear, 'rgb(255,165,0)' ],
  ], 'rgb(0,255,0)');

  const defaultLabel = <>{daysDiff.toString() + " Days"}</>;
  const longTrialLabel = <>{daysDiff.toString() + " Days"}<br /><b>Long Trial</b></>;
  const over365Label = () => {
    const { years, months, days } = getTimeBreakdown(daysDiff);

    return <table>
      <tbody>
        <tr><th>Years</th><td>{years}</td></tr>
        <tr><th>Months</th><td>{months}</td></tr>
        <tr><th>Days</th><td>{days} </td></tr>
      </tbody>
    </table>;
  };

  const label = firstMatch([
    [isLongTrial, longTrialLabel],
    [spansMoreThanAYear, over365Label()],
  ], defaultLabel);

  return { label, color };
};

const typeConfig: Record<
number, {
  componentName: string,
  component: (props: {
    subscription: Subscriptions;
    setSubscriptionProp: <T extends keyof Subscriptions>(prop: T, newVal: Subscriptions[T]) => void;
  }) => JSX.Element,
  frequencies: number[],
}
> = {
  [BillingType.FLAT_FEE]: {
    componentName: 'flat_fee',
    component: FlatFee,
    frequencies: [
      BILLING_FREQUENCY_QUARTERLY,
      BILLING_FREQUENCY_SEMI_ANNUALLY,
      BILLING_FREQUENCY_YEARLY,
    ],
  },
  [BillingType.ACTIVE_BUILDING]: {
    componentName: 'active_building',
    component: ActiveBuilding,
    frequencies: [ BILLING_FREQUENCY_DAILY ],
  },
  [BillingType.COST_PER_LEAD]: {
    componentName: 'cost_per_lead',
    component: CostPerLead,
    frequencies: [],
  },
  [BillingType.PROPERTY_VOLUME]: {
    componentName: 'variable_base_price',
    component: VariableBasePrice,
    frequencies: [],
  },
  [BillingType.CALL_VOLUME]: callTrackingConfig,
  [BillingType.SMS_VOLUME]: callTrackingConfig,
  [BillingType.CALL_RECORDING]: callTrackingConfig,
  [BillingType.LINE_BUNDLE]: callTrackingConfig,
  [BillingType.LINE_RECORDING_BUNDLE]: callTrackingConfig,
  [BillingType.LIFT_BUILDING]: {
    componentName: 'lift_building',
    component: LiftSubscription,
    frequencies: [],
  },
  [BillingType.PROMOTED_LISTING_CONTRACT]: {
    componentName: 'promoted_listing_contract',
    component: PromotedListingContract,
    frequencies: [],
  },
  [BillingType.PROMOTED_LISTING]: {
    componentName: 'promoted_listing',
    component: PromotedListing,
    frequencies: [],
  },
};

export const RecurringSubscription = (
  {
    subscription,
    setSubscription,
    billingFrequencies,
  }: {
    subscription: Subscriptions,
    setSubscription: React.Dispatch<React.SetStateAction<Subscriptions | null>>,
    billingFrequencies: BillingFrequencies[],
  },
) => {
  const setSubscriptionProp = useCallback(<T extends keyof Subscriptions>(prop: T, newVal: Subscriptions[T]) => {
    setSubscription({ ...subscription, [prop]: newVal });
  }, [ subscription, setSubscription ]);

  const [ noExpiry, setNoExpiry ] = useState<boolean>(!subscription.expiryDate);
  useEffect(() => {
    if (noExpiry && subscription.expiryDate) {
      setSubscriptionProp('expiryDate', null);
    } else if (!noExpiry && !subscription.expiryDate) {
      setSubscriptionProp('expiryDate', (new Date()).toISOString());
    }
  }, [ noExpiry, subscription.expiryDate, setSubscriptionProp ]);

  const [ discountRateFromSub, setDiscountFromSub ] = useState<number>(
    floatVal(getDiscountRateFromSubscription(subscription)),
  );
  const [ humanDiscount, setHumanDiscount ] = useState<number>(floatVal(getDiscountRateFromSubscription(subscription)));
  useEffect(() => {
    const newRate = humanDiscount / 100;
    if (Number.isNaN(newRate)) {
      setSubscriptionProp('discountRate', `0`);
    } else if (floatVal(discountRateFromSub) !== newRate) {
      setSubscriptionProp('discountRate', `${newRate}`);
    }
  }, [ humanDiscount, discountRateFromSub, setSubscriptionProp ]);

  const requiresFiscalMonth = useMemo(() => {
    return (
      ![BILLING_FREQUENCY_ONE_TIME, BILLING_FREQUENCY_DAILY, BILLING_FREQUENCY_WEEKLY, BILLING_FREQUENCY_MONTHLY]
        .includes(subscription.billingFrequencyId)
    );
  }, [ subscription.billingFrequencyId ]);

  // 3. Discounts should not be modified at this time (Only if you're not an LWS admin... But who else would be in this form?)
  // preventModifications(loadedSubscription, ['discount-rate', 'discount']);

  const billingTypeConfig = useMemo(() => (
    typeConfig[intVal(subscription.baseSubscription.service.billingTypeId)] ?? null
  ), [ subscription ]);

  const availableBillingFrequencies = useMemo(() => {
    const availableIds = [
      BILLING_FREQUENCY_MONTHLY,
      ...(billingTypeConfig.frequencies ?? []),
    ];
    return billingFrequencies.filter(bf => availableIds.includes(bf.id));
  }, [ billingTypeConfig, billingFrequencies ]);

  const startTime = useMemo(() => (
    !subscription.startDate
      ? null
      : (new Date(subscription.startDate)).getTime()
  ), [ subscription.startDate ]);

  const expiryTime = useMemo(() => (
    !subscription.expiryDate
      ? null
      : (new Date(subscription.expiryDate)).getTime()
  ), [ subscription.expiryDate ]);

  const {
    label: subscriptionSpanLabel,
    color: subscriptionCellBgColor,
  } = useMemo(() => calculateSubscriptionSpan({
    start: startTime,
    end: expiryTime,
    discountRate: discountRateFromSub,
  }), [ startTime, expiryTime, discountRateFromSub ]);

  return (<Grid item xs={12}>
    {/* <div>{subscription.baseSubscription.service.billingTypeId}</div> */}
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {/* <td><label htmlFor="discount">Discount</label></td> */}
        <TextField
          type="text"
          size="small"
          label="Discount"
          id="discount"
          value={humanDiscount}
          onChange={(e) => setHumanDiscount(floatVal(e.target.value || '0'))}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={12}>
        {/* <td><label htmlFor="billing-frequency">Billing Frequency</label></td> */}
        {
          Object.keys(availableBillingFrequencies).length === 1
            ? first(Object.values(availableBillingFrequencies)) ?? null
            : <Select
              id="billing-frequency"
              size="small"
              label='Billing Frequency'
              name="subscription[billing_frequency_id]"
              value={subscription.billingFrequencyId}
              onChange={(e) => setSubscriptionProp('billingFrequencyId', intVal(e.target.value))}
            >
              {availableBillingFrequencies.map((fq) => <MenuItem key={fq.id} value={fq.id}>{fq.name}</MenuItem>)}
            </Select>
        }
      </Grid>
      {
        requiresFiscalMonth
          && <Grid item xs={12}>
            <Select
              label="First Fiscal Month"
              id="first-fiscal-month"
              size="small"
              name="subscription[first_fiscal_month]"
              title="This will be the first month in the cycle for a clients quarerly/semi-annual/yearly billing"
              value={subscription.firstFiscalMonth}
              onChange={(e) => setSubscriptionProp('firstFiscalMonth', intVal(e.target.value))}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                <MenuItem key={n} value={n}>{getMonthName(dateFromYmd(`2022-${n}-01`))}</MenuItem>
              ))}
            </Select>
          </Grid>
      }
      <Grid item xs={4}>
        <HHDatePicker
          label="Start Date"
          value={new Date(subscription.startDate ?? new Date())}
          onChange={(d: Date | null) => { if (d) { setSubscriptionProp('startDate', d.toISOString()); } }}
        />
      </Grid>
      <Grid item xs={4}>
        {
          subscription.expiryDate
              && <>
                <HHDatePicker
                  label="End Date"
                  value={new Date(subscription.expiryDate ?? new Date())}
                  onChange={(d: Date | null) => { if (d) { setSubscriptionProp('expiryDate', d.toISOString()); } }}
                />
              </>
        }

        <FormControlLabel
          control={<Switch checked={noExpiry} onChange={(e) => setNoExpiry(e.target.checked)} />}
          label="No Expiry"
        />
      </Grid>
      <Grid item xs={4} style={{ backgroundColor: subscriptionCellBgColor ?? '' }}>
        {subscriptionSpanLabel}
      </Grid>
      <Grid item xs={12}>
        {billingTypeConfig.componentName}
        {billingTypeConfig.component({ subscription, setSubscriptionProp })}
      </Grid>
    </Grid>
  </Grid>);
};
