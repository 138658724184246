import { Hash } from "@jamesgmarks/utilities";
import { PdfGeneration } from "../../../../../../entities/hydra";
import { IInvoice } from "../../../../entity-interfaces/IInvoice";
import { ICreditNote } from "../../../../interfaces/ICreditNote";
import { billingRunUpdated } from "../../../../redux/features/billing-runs/billingRunSlice";
import {
  creditNoteUpdated,
  currentCreditNotePdfGenerated as currentCreditNotePdfGeneratedReducer,
} from "../../../../redux/features/credit-notes/creditNotesSlice";
import {
  invoiceDownloadQueueLengthReceived,
  invoiceEmailSendQueueLengthReceived,
  invoicesUpdated, invoiceUpdated,
} from "../../../../redux/features/invoices/invoiceSlice";
import { setEmbedUrlForVideo, showEmbed, showMessage } from "../../../../redux/features/messaging/actions";
import { setRequiredUiVersion } from "../../../../redux/features/systemInfo/actions";
import { dispatch } from "../../../../redux/store";
import { Equal } from "@llws/dynamic-router-utils";
import { getMany } from "src/redux/features/dynamic-hydra/actions";

/**
 * Called when the `billingRun_updated` event is received from the server.
 *
 * Dispatches a call to the `billingRunUpdated` reducer in the billing runs redux store.
 */
export const updateBillingRun = (eventData: Hash) => {
  dispatch(billingRunUpdated({ billingRun: eventData }));
};

/**
 * Called when the `invoice_updated` event is received from the server.
 *
 * Dispatches a call to the `invoiceUpdated` reducer in the invoices redux store.
 */
export const updateInvoice = (eventData: Hash) => {
  dispatch(invoiceUpdated(eventData.invoice as IInvoice));
};

/**
 * Called when the `invoices_updated` socket event is received from the server.
 *
 * Dispatches a call to the `invoicesUpdated` reducer in the invoices redux store.
 */
export const updateInvoices = (eventData: Hash) => {
  dispatch(invoicesUpdated(eventData.invoices as IInvoice[]));
};

/**
 * Called when the `notifications_updated` socket event is received from the server.
 *
 * Triggers the dynamic-hydra store `getMany` action to fetch the latest list of notifications.
 */
export const updateNotifications = ({ userId, modifiedUserId }: Hash) => {
  if (
    (
      !userId
      || typeof userId !== 'number'
    ) || (
      modifiedUserId
      && typeof modifiedUserId !== 'number'
    )
  ) {
    console.warn('updateNotifications called with invalid userId or modifiedUserId. Aborted.');
    return;
  }
  getMany('notifications', { where: [ Equal('userId', userId ?? modifiedUserId ?? 0) ] });
};

/**
 * Events are fired when a credit note PDF is generated.
 * This appends the new PDF generation record to `state.currentCreditNote`.
 * @param eventData This will have a property called `pdfGeneration` with the eponymous entity.
 */
export const currentCreditNotePdfGenerated = (eventData: Hash): void => {
  dispatch(currentCreditNotePdfGeneratedReducer(eventData.pdfGeneration as PdfGeneration));
};

export const updateCreditNote = (eventData: Hash) => {
  dispatch(creditNoteUpdated(eventData.creditNote as ICreditNote));
};

export const showSystemNotification = ({ actionButtonInfo, asAlert, message, severity }: Hash) => {
  showMessage({
    actionButtonInfo,
    asAlert: asAlert ? asAlert : severity === 'error',
    message,
    severity: severity ?? 'info',
  });
};

export const requireVersion = (eventData: Hash) => {
  setRequiredUiVersion(eventData.version);
};

export const setInvoiceGenerateUploadQueueLength = ({ queueLength }: Hash) => {
  dispatch(invoiceDownloadQueueLengthReceived(queueLength));
};

export const setInvoiceEmailSendQueueLength = ({ queueLengthChange }: Hash) => {
  dispatch(invoiceEmailSendQueueLengthReceived(queueLengthChange));
};

export const showEmbedFromSocket= ({url, show}:Hash) =>{
  setEmbedUrlForVideo(url);
  showEmbed(show);
};
