import { RentsyncBuildings } from "./RentsyncBuildings";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const CostPerLead = ({
  subscription, setSubscriptionProp,
}: TEditorComponentProps) => {
  return (<>
    <div className="input-section">
      <table className="table">
        <tr>
          <td><label htmlFor="base-price">Base Price</label></td>
          <td>
            $<input
              type="text"
              id="base-price"
              name="subscription[base_price]"
              value={subscription.basePrice ?? ''}
              onChange={(e) => setSubscriptionProp('basePrice', e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td><label htmlFor="cost-per-lead-max">Cost Per Lead Max</label></td>
          <td>
            <input
              id="cost-per-lead-max"
              type="text"
              name="subscription[cost_per_lead_max]"
              value={subscription.costPerLeadMax ?? ''}
              onChange={(e) => setSubscriptionProp('costPerLeadMax', e.target.value)}
            />
          </td>
        </tr>
      </table>
    </div>

    <div className="input-section">
      <div className="input-section rentsync-building-section">
        <RentsyncBuildings subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
      </div>
    </div>
  </>);
};
