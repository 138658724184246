import { Subscriptions } from "@llws/typeorm-entities";
import { Grid, TextField } from "@mui/material";
import { useCallback } from "react";
import { Submit } from "./Submit";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const LiftSubscription = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  const setLiftSubscriptionProp = useCallback(
    <T extends keyof Subscriptions['liftSubscriptions']>(prop: T, newVal: Subscriptions['liftSubscriptions'][T]) => {
      if (!subscription) return;
      setSubscriptionProp('liftSubscriptions', { ...subscription.liftSubscriptions, [prop]: newVal });
    },
    [ subscription, setSubscriptionProp ],
  );

  return (
    <Grid container>
      <div className="input-section">
        <input type="hidden" id="lift-subscription-id" name="lift_subscription[id]" />
        <table className="table" id="lift-subscription-input-table">
          <tr>
            <td><label htmlFor="base-price">Base Price</label></td>
            <td>
            $<TextField
                id="base-price"
                type="text"
                name="subscription[base_price]"
                value={subscription.basePrice ?? ''}
                onChange={(e) => setSubscriptionProp('basePrice', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td><label htmlFor="minimum-value">Minimum Value</label></td>
            <td>
            $<TextField
                id="minimum-value"
                type="text"
                name="lift_subscription[minimum_value]"
                value={subscription.liftSubscriptions.minimumValue}
                onChange={(e) => setLiftSubscriptionProp('minimumValue', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td><label htmlFor="cost-per-single-family-home">Cost Per Single Family Home</label></td>
            <td>
            $<TextField
                id="cost-per-single-family-home"
                type="text"
                name="lift_subscription[cost_per_single_family_home]"
                value={subscription.liftSubscriptions.costPerSingleFamilyHome ?? ''}
                onChange={(e) => setLiftSubscriptionProp('costPerSingleFamilyHome', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td><label htmlFor="cost-per-multi-family-home">Cost Per Multi Family Home</label></td>
            <td>
            $<TextField
                id="cost-per-multi-family-home"
                type="text"
                name="lift_subscription[cost_per_multi_family_home]"
                value={subscription.liftSubscriptions.costPerMultiFamilyHome ?? ''}
                onChange={(e) => setLiftSubscriptionProp('costPerMultiFamilyHome', e.target.value)}
              />
            </td>
          </tr>
        </table>
      </div>

      <Submit subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
    </Grid>
  );
};
