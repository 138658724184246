import { createSlice } from '@reduxjs/toolkit';
import { IActionType, IApiQueryListResponse, IApiQuerySingularResponse } from '../../utils';
import { TLoadedState } from '../../../types/TLoadedState';
import { THydraDynamicTypeKey, THydraDynamicTypesMap, THydraDynamicTypeTypeValue } from './THydraDynamicTypesMap';
import { Nullable, replaceAt } from '@jamesgmarks/utilities';
import { IApiQueryResponse } from '@llws/api-common';

type TDynamicStoreData = {
  [K in keyof THydraDynamicTypesMap]: {
    loadedState: TLoadedState,
    single: null | THydraDynamicTypesMap[K],
    list: null | THydraDynamicTypesMap[K][],
  }
}

export type TDynamicState = {
  data: TDynamicStoreData,
  metaData: Nullable<IApiQueryResponse<THydraDynamicTypeTypeValue>['meta']>,
};

const updateDynamicState = <T extends keyof THydraDynamicTypesMap>(
  startState: TDynamicStoreData,
  resource: T,
  newState: Partial<{} & TDynamicStoreData[T]>,
) => {
  const listItemIndex = startState[resource]?.list?.findIndex(item => (
    (
      'id' in item && newState.single && 'id' in newState.single
      && item.id === newState.single.id
    ) || (
      'subscriptionId' in item
      && newState.single
      && 'subscriptionId' in newState.single
      && item.subscriptionId === newState.single.subscriptionId
    ) || false
  )) ?? -1;

  const oldList = (startState[resource]?.list ?? []);

  const newList = newState.list ?? (
    listItemIndex >= 0 && newState.single
      ? replaceAt(oldList, listItemIndex, newState.single as THydraDynamicTypesMap[T])
      : oldList
  );

  return {
    ...startState,
    [resource]: {
      loadedState: newState?.loadedState ?? startState[resource]?.loadedState ?? 'idle',
      single: newState?.single ?? startState[resource]?.single ?? null,
      list: newList,
    },
  };
};

export const dynamicHydraSlice = () => createSlice({
  name: 'dynamic',
  initialState: {
    data: {},
    metaData: null,
  } as TDynamicState,
  reducers: {
    setLoadedState: (state, action: IActionType<{
      resource: THydraDynamicTypeKey,
      newState: TLoadedState,
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        loadedState: action.payload.newState,
      });
    },
    dynamicSingleReceived: (state, action: IActionType<{
      resource: THydraDynamicTypeKey
      response: IApiQuerySingularResponse<THydraDynamicTypesMap[THydraDynamicTypeKey]>
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        single: action.payload.response.data as unknown as THydraDynamicTypesMap['subscriptions'], // This is cheating, but it works
      });
    },
    dynamicManyReceived: (state, action: IActionType<{
      resource: THydraDynamicTypeKey
      response: IApiQueryListResponse<THydraDynamicTypesMap[THydraDynamicTypeKey]>
    }>) => {
      state.data = updateDynamicState(state.data, action.payload.resource, {
        list: action.payload.response.data as unknown as THydraDynamicTypesMap['subscriptions'][], // This is cheating, but it works
      });
      state.metaData = { ...action.payload.response.meta };
    },
    clearDynamicDataForResource: (state, action: IActionType<{ resource: THydraDynamicTypeKey }>) => {
      state.data[action.payload.resource] = {
        loadedState: 'idle',
        single: null,
        list: [],
      };
    },
  },
});

const slice = dynamicHydraSlice();

// Action creators are generated for each case reducer function
export const {
  setLoadedState,
  dynamicSingleReceived,
  dynamicManyReceived,
  clearDynamicDataForResource,
} = slice.actions;

export default slice.reducer;
