import { Grid, InputAdornment, TextField } from "@mui/material";
import { RentsyncBuildings } from "./RentsyncBuildings";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const ActiveBuilding = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          type="text"
          size="small"
          label="Base Price"
          id="base-price"
          name="subscription[base_price]"
          value={subscription.basePrice ?? ''}
          onChange={(e) => {
            const num = Number.parseFloat(e.target.value.trim());
            setSubscriptionProp('basePrice', Number.isNaN(num) ? null : `${num}`);
          }}
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className="input-section rentsync-building-section">
          <RentsyncBuildings subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
        </div>
      </Grid>
    </Grid>
  );
};
