import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Submit } from "./Submit";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const PromotedListing = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  /*
    <?php
      $contract_sub = Subscriptions::get_contract_subscription_summary_for_promoted_listing_sub($subscription_id);
    ?>
  */

  const contractSub = useMemo(() => ({ id: 123, invoiceDescription: 'ABC1123' }), []);

  return (<>
    <h3>Promoted Listing Contract</h3>
    <table id="promoted-listing-contract-summary" className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="glimpse"
            data-model="subscription"
            data-id={`${contractSub.id}`}
          >
            <Link to={`/subscriptions/${contractSub.id}`}>{contractSub.id}</Link>
          </td>
          <td>
            {contractSub.invoiceDescription}
          </td>
        </tr>
      </tbody>
    </table>
    <Submit subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
  </>);
};
