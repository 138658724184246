import { TEditorComponentProps } from "./TEditorComponentProps";
import { VariableBasePrice } from "./VariableBasePrice";

export const CallTracking = ({
  subscription, setSubscriptionProp,
}: TEditorComponentProps) => {
  return (<>
    <div className="input-section">
      <label
        title="All billing for toll-free numbers will be multiplied by this number"
        htmlFor="toll-free-multiplier"
      >
        Toll-Free Multiplier
      </label>
      <input
        type="text"
        id="toll-free-multiplier"
        name="subscription[base_price_scalar]"
        value={subscription.basePriceScalar}
        onChange={(e) => setSubscriptionProp('basePriceScalar', e.target.value)}
      />x
    </div>
    <VariableBasePrice subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
  </>);
};
