import { useCallback, useEffect, useState } from "react";

import {
  Button, Col, Form, FormControl, Modal, Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";

import { floatVal, Nullable } from "@jamesgmarks/utilities";

import {
  IApplyCreditModalSubmitData,
  IApplyCreditNoteModalSubmitData,
} from "../../../interfaces/IApplyCreditModalSubmitData";
import { ICreditSummaryData } from "../../../interfaces/ICreditSummaryData";
import { ICreditNoteSummaryItem, ICreditSummaryItem } from "../../../interfaces/ICreditSummaryItem";
import { Invoice } from "../../../../../entities/hydra";
import { loadMonthLockData } from "../../../redux/features/invoices/actions";
import { showMessage } from "../../../redux/features/messaging/actions";
import { useAppSelector } from "../../../redux/hooks";
import { toDollarAmount } from "../../../app-utils";

export const ReductionConfirmationModal = ({
  show,
  onHide,
  onSubmit,
} : {
    show: boolean,
    onHide?: (() => void),
    onSubmit?: (() => void)
  }) => {

  return (<>

    <Modal
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are You Sure</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          Press Ok to continue applying the credit as a reduction/line item.
          Press Cancel to continue applying the credit as a payment.

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
            Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>OK</Button>
      </Modal.Footer>
    </Modal>
  
  </>);

};

/**
 * Given a subtotal and decimal tax rate, calculates a fixed-digit-after-decimal
 * number of either the total tax value, or the tax value plus the subtotal (total credit amount).
 * @param subtotal - A number representing the pre-tax total.
 * @param taxRate  - A decimal (e.g. `0.13`) representing the tax rate.
 * @param onlyTax  - When `true`, returns only the tax value, rather than subtotal + tax. Default `false`.
 * @param fixedDigits - The number of digits after the decimal. Default `2`.
 * @returns Either the total tax to pay, or the subtotal plus the tax to pay.
 */
const calculateFixedInvoiceTotalValue = (
  subtotal: number, 
  taxRate: number, 
  onlyTax: boolean = false,
  fixedDigits: number = 2,
) => {
  const subtotalInclusionConstant = onlyTax ? 0 : 1;
  return (subtotal * (subtotalInclusionConstant + taxRate)).toFixed(fixedDigits);
};

const isICreditSummaryItem = (obj: unknown): obj is ICreditSummaryItem => (
  !!(obj as ICreditSummaryItem).relatedInvoiceId
);

interface IApplyCreditModalProps {
  creditData: ICreditSummaryData;
  invoice: Nullable<Invoice>;
  onHide?: (() => void);
  onCreditSubmit: (submitData: IApplyCreditModalSubmitData) => void;
  onCreditNoteSubmit?: (submitData: IApplyCreditNoteModalSubmitData) => void;
  show: boolean;
}

export const ApplyCreditModal = ({
  creditData,
  invoice,
  onHide,
  onCreditSubmit,
  onCreditNoteSubmit,
  show,
} : IApplyCreditModalProps) => {
  const [ applyType, setApplyType ] = useState<'credit' | 'creditNote'>('credit');
  const [ selectedCreditNoteIdentifier, setSelectedCreditNoteIdentifier ] = useState<Nullable<string>>(null);

  const maxApplicationAmount = (
    applyType === 'credit'
      ?
      Math.min(
        floatVal(invoice?.freshbooksOutstandingAmount ?? invoice?.amountInvoiced) ?? creditData.availableSubtotal,
        creditData.availableSubtotal,
      )
      : (
        creditData.creditNoteSummary
          .find(({ identifier }) => identifier === selectedCreditNoteIdentifier)?.remainingBalance ?? 0
      )
  ); // lowest of invoice max subtotal and total available credit
  const invoiceDate = new Date(invoice?.invoiceDate ?? '');

  const monthLocks = useAppSelector(state => state.invoices.monthLocks);
  
  const invoiceYear = invoiceDate.getFullYear();
  const invoiceMonth = invoiceDate.getMonth() + 1;
  const monthLockForMonth = invoice ? monthLocks[`${invoiceYear}-${invoiceMonth}`] ?? null : null;
  if (invoice && invoiceYear && invoiceMonth && monthLockForMonth === null) {
    loadMonthLockData(invoiceYear, invoiceMonth);
  }
  const isMonthLocked = monthLockForMonth;
  const canApplyAsLineItem = (
    !isMonthLocked && ['draft', 'sent', 'viewed', 'partial'].includes(invoice?.freshbooksState ?? '')
  );

  const [ source, setSource ] = useState<Nullable<ICreditSummaryItem | ICreditNoteSummaryItem>>(null);
  const [ description, setDescription ] = useState('');
  const [ amount, _setAmount ] = useState(maxApplicationAmount);
  const [ date, setDate ] = useState(new Date());
  const [ appliedAs, setAppliedAs ] = useState(
    ('payment') as 'reduction' | 'payment',
  );

  const [showReductionConfirmation, setShowReductionConfirmation] = useState(false);

  const handleCloseReductionConfirmation = (() => setShowReductionConfirmation(false));
  const handleShowReductionConfirmation = (() => setShowReductionConfirmation(true));

  const setSourceByIdentifier = (identifier: Nullable<string>) => {
    setSelectedCreditNoteIdentifier(identifier);

    const newSource = (
      applyType === 'credit'
        ? (creditData.creditSummary ?? []).find((cr) => cr.identifier === identifier) ?? null
        : (creditData.creditNoteSummary ?? []).find((creditNote) => creditNote.identifier === identifier) ?? null
    );

    setSource(newSource);

    if (newSource) {
      setDescription(
        isICreditSummaryItem(newSource)
          ? `[${newSource.identifier}] ${(newSource).description ?? ''}`
          : `Credit Note ${newSource.identifier}`,
      );

      setAmount(!isICreditSummaryItem(newSource) ? newSource.total : newSource.subtotal);
    } else {
      setDescription('');
      setAmount(0);
    }
  };

  const setAmount = useCallback(
    (amount: number) => {
      const newAmount = Math.max(0, Math.min(maxApplicationAmount, amount));
      _setAmount(newAmount);
    }, [ maxApplicationAmount ],
  );
  
  useEffect(() => {
    setAmount(maxApplicationAmount);
  }, [ maxApplicationAmount, setAmount ]);

  const [appliableCredits, setAppliableCredits] = useState<ICreditSummaryItem[]>([]);

  useEffect(() => {
    const {creditSummary, creditsSpent} = creditData;

    const creditsThatCanBeApplied = (
      creditSummary.filter((c) => (
        c.type === 'credit'
          && (
            c.subtotal
            > creditsSpent
              .filter(({ creditId }) => creditId === c.id)
              .reduce((acc, { amount }) => acc + floatVal(amount), 0)
          )
      ))
    );

    setAppliableCredits(creditsThatCanBeApplied);
  }, [creditData]);

  const [ lockedMonthChosen, setLockedMonthChosen ] = useState(false);

  useEffect(() => {
    const updateMonthLocks = async (year: number, month: number) => {
      await loadMonthLockData(year, month);
    };

    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    if (!(`${year}-${month}` in monthLocks)) {
      updateMonthLocks(year, month);
    }

    if (monthLocks[`${year}-${month}`]) {
      setLockedMonthChosen(true);
    } else {
      setLockedMonthChosen(false);
    }
  }, [ monthLocks, date ]);

  return (
    <Modal
      centered
      dialogClassName="modal-60w"
      backdrop="static"
      animation={false}
      show={show}
      onClose={() => onHide!()}
    >
      <Modal.Header>
        <Modal.Title>
          Apply Credit or Credit Note
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showReductionConfirmation && <ReductionConfirmationModal
          show={showReductionConfirmation} 
          onHide={() => {handleCloseReductionConfirmation();}}
          onSubmit = {() => {
            handleCloseReductionConfirmation();
            setAppliedAs('reduction' as 'reduction' | 'payment');
          }}
        />
        }
        <Row className='mb-2'>
          <Col sm="3">Method:</Col>
          <Col sm="9">
            <Form.Control
              as="select"
              placeholder="Choose"
              value={`specific${applyType && '-'}${applyType}`}
              onChange={(e) => {
                const [ _, applyType ] = e.target.value.split('-');
                setApplyType(applyType as 'credit' | 'creditNote');
              }}
            >
              <option value="specific-credit">Apply from Specific Credit</option>
              <option value="specific-creditNote">Apply from Specific Credit Note</option>
            </Form.Control>
          </Col>
        </Row>
        
        <>
          <Row className='mb-1'>
            <Col sm="3">Credit{applyType !== 'credit' && ' Note'}:</Col>
            <Col sm="9">
              <Form.Control
                as="select"
                placeholder="Choose"
                value={source?.identifier ?? ''}
                onChange={(e) => {
                  setSourceByIdentifier((e.target.value || null));
                }}
              >
                <option></option>
                {
                  applyType === 'credit'
                    ?                    
                    appliableCredits
                      .map((c) => (
                        <option key={c.identifier} value={c.identifier}>
                          {c.identifier} - {(c.description ?? '').slice(0, 100)}
                        </option>
                      ))
                    : creditData.creditNoteSummary.filter((c) => c.remainingBalance > 0).map((c) => (
                      <option key={c.id} value={c.identifier}>
                          CN #{c.identifier} - {
                          c.credits.length} credit{
                          c.credits.length === 1 ? '' : 's'} - {
                          toDollarAmount(floatVal(c.subtotal) + floatVal(c.tax))
                        }
                      </option>
                    ))
                }
              </Form.Control>
            </Col>
          </Row>
        </>
        
        {
          source
          && maxApplicationAmount
          &&
          <>
            <Row><Col>
              <Form.Label>
                Description:
              </Form.Label>
            </Col></Row>
            <Row className='mb-2'>
              <Col sm="12">
                <FormControl
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Amount ($):
              </Form.Label>
              <Col sm="9">
                <FormControl
                  as="input"
                  type="number"
                  value={floatVal(amount)}
                  onChange={
                    (e) => setAmount(
                      Number.isNaN(floatVal(e.target.value))
                        ? 0
                        : Math.max(0, Math.min(floatVal(e.target.value), maxApplicationAmount)),
                    )
                  }
                />
                <small>Maximum applicable amount: {toDollarAmount(maxApplicationAmount)}</small>
              </Col>
            </Form.Group>
            {
              isICreditSummaryItem(source)
              && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Tax @ {floatVal(invoice?.taxRate ?? '0') * 100}%:
                    </Form.Label>
                    <Col sm="9">
                      {calculateFixedInvoiceTotalValue(amount, floatVal(invoice?.taxRate ?? '0'), true)}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Total:
                    </Form.Label>
                    <Col sm="9">
                      {calculateFixedInvoiceTotalValue(amount, floatVal(invoice?.taxRate ?? '0'))}
                    </Col>
                  </Form.Group>
                </>
              )
            }
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Application Date:
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  selected={date}
                  onChange={(date) => date && !Array.isArray(date) ? setDate(date) : null}
                />
                {
                  lockedMonthChosen && <small style={{ marginLeft: 10, color: 'red' }}>Month is locked!</small>
                }
              </Col>
            </Form.Group>
            <Row>
              <Col sm="3">Applied As:</Col>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Choose"
                  value={appliedAs ?? ''}
                  onChange={(e) => {
                    if(e.target.value === 'reduction'){
                      handleShowReductionConfirmation();
                    }else {
                      setAppliedAs((e.target.value || null) as 'payment');
                    }
                  }}
                  disabled= {!canApplyAsLineItem}
                >
                  <option value="reduction">Line Item</option>
                  <option value="payment">Payment</option>
                </Form.Control>
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={lockedMonthChosen || amount <= 0}
          onClick={() => {
            if (applyType === 'credit') {
              if (!(invoice?.id)) {
                showMessage({ message: 'Cannot submit. Missing invoice ID.' });
                throw new Error();
              }

              onCreditSubmit({
                freshbooksClientId: invoice!.freshbooksClientId,
                invoiceId: invoice!.id,
                creditId: source?.id ?? undefined,
                amount,
                taxRate: floatVal(invoice?.taxRate ?? '0'),
                taxAmount: floatVal(amount) * floatVal(invoice?.taxRate ?? '0'),
                description,
                currencyCode: (invoice?.currency as 'CAD' | 'USD') ?? 'CAD',
                dateApplied: date.toISOString(),
                appliedAs,
              });
            } else {
              onCreditNoteSubmit?.({
                freshbooksClientId: invoice!.freshbooksClientId,
                invoiceId: invoice!.id,
                creditNoteId: source!.id,
                amount,
                currencyCode: invoice?.currency ?? 'CAD',
                dateApplied: date.toISOString(),
                taxRate: floatVal(invoice?.taxRate ?? '0'),
              });
            }

            onHide!();
          }}
        >
          Add
        </Button>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
