import { InputAdornment, TextField } from "@mui/material";
import { FlatFeeBuildings } from "./FlatFeeBuildings";
import { TEditorComponentProps } from "./TEditorComponentProps";

export const FlatFee = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  return (<>
    <div className="input-section">
      <TextField
        label="Base Price"
        type="text"
        size="small"
        id="base-price"
        name="subscription[base_price]"
        value={subscription.basePrice ?? ''}
        onChange={(e) => setSubscriptionProp('basePrice', e.target.value)}
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      />
    </div>

    <div className="input-section">
      {/* <div className="input-section flat-fee-building-section"> */}
      <FlatFeeBuildings subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
      {/* </div> */}
    </div>
  </>);
};
