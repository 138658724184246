import { floatVal } from "@jamesgmarks/utilities";
import { useMemo } from "react";
import { roundFloatToCents } from "../../../../app-utils";
import { TEditorComponentProps } from "./TEditorComponentProps";

// TODO: What is the difference between FlatFeeBuildings and RentsyncBuildings?
export const FlatFeeBuildings = ({ subscription, setSubscriptionProp }: TEditorComponentProps) => {
  // TODO: Subscription Buildings Dropdown Component

  // 1. If I have subscription buildings, then show the subscriptions has buildings table.

  const totalBuildingPrice = useMemo(() => {
    return subscription.subscriptionsHasBuildings.reduce((acc, curr) => acc + floatVal(curr.price), 0);
  }, [ subscription.subscriptionsHasBuildings ]);

  return (<>
    <label htmlFor="building-search">
      <h2>Buildings: (Flat Fee Buildings)</h2>
    </label>

    {/**
     * This is where the building select goes.
     * Note that the legacy code provided a blacklist of buildings that could be added to the list because they were already in use.
     **/}

    <table id="subscription-buildings-table">
      <thead>
        <tr>
          <th>Building</th>
          <th>Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {subscription.subscriptionsHasBuildings.map((shb) => (
          <tr
            data-building-id={shb.buildingId}
            data-sub-building-id={shb.id}
          >
            <td title={shb.building.streetNumber + ' ' + shb.building.streetName}>
              {shb.building.buildingName}
            </td>
            <td>
            $<input
                className="building-price-input"
                type="text"
                name={`subscription_buildings[update][${shb.id}]`}
                value={roundFloatToCents(floatVal(shb.price))}
              />
            </td>
            <td>
              <button className="small red button" type='button'>remove</button>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={3}>
            Let's put the ADD PROPERTY controls here instead of above.
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Total</td>
          <td id="building-price-sum">{totalBuildingPrice}</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </>);
};
